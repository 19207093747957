import * as React from "react"
import { Link } from "gatsby"
import Layout from '../shared/Full'
import styled from "styled-components"

const HeroMessageTwo = ({topLine, bottomLine}) => {

  const ActionButton = styled.button`
    width: 250px;
    height: 60px;
    background: transparent;
    border: 2px solid #FDF9F0;
    border-radius: 10px;
    color: #FDF9F0;
    margin: 0 auto; 
    line-height: 0px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 16px;
  `;

  return(
    <>
    <div className="big-boy">
      <h1>{topLine}<br/>{bottomLine}</h1>
      <ActionButton>Gå till start</ActionButton>
    </div>
    </>
  )
}

// markup
const NotFoundPage = () => {
  const Frame = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background: #282828;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-color: #282828;
  `;
  return (
    <Layout>
    <Frame>
    <HeroMessageTwo topLine="404 - sidan hittades inte" bottomLine="den är borta" />
    </Frame>
    </Layout>
  )
}

export default NotFoundPage
